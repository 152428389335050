<div *ngIf="loaded">

    <!-- HOME BUTTON -->
    <div id="scroll-up" class="{{ start ? 'show' : '' }}">
        <a pageScroll href="/{{ code }}/{{ lang }}#welcome">
            <img src="/assets/images/common/img-house.png" alt=""> 
        </a>
    </div>
    <!-- -->

    <!-- LANG SELECTOR -->
    <div id="lang" class="{{ start ? 'show' : '' }}">
        <ng-select [(ngModel)]="lang" [items]="langs" [bindValue]="'lang'" [clearable]="false" [searchable]="false" class="mx-auto mx-md-0 text-start" (change)="change($event)"> 
            <ng-template ng-label-tmp let-item="item">
                <img src="/assets/langs/{{ item.lang }}.png" />
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span class="ng-option-label ng-star-inserted" ng-reflect-ng-item-label="{{item.label}}" ng-reflect-escape="true"><img src="/assets/langs/{{ item.lang }}.png" /> {{ item.label }}</span>       
            </ng-template>      
        </ng-select>
    </div>
    <!-- -->



    <!-- HOME SECTION --> 
    <section id="start">
        <div class="d-block d-md-flex">
            <div class="w-100 w-md-50 position-relative col-l">
                <img src="{{ imgs }}" alt="Logo">
                <div class="circle">
                    <img data-aos="fade-in" src="/assets/images/start/img-circle.png" alt="">
                </div>
            </div>
            <div class="col-r">
                <div>
                    <div class="d-none d-md-block start-logo pt-4">
                        <img data-aos="fade-down" src="/assets/images/start/img-logo-rci.svg" alt="RCI">
                    </div>
                    <div class="start-text">
                        <p [innerHTML]="'start.purchasing' | translate"></p>
                        <h3 data-aos="fade-in" class="text-center text-md-start" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" [innerHTML]="'start.all-in-one' | translate"></h3>
                        <div class="start-logo-text text-center mt-5">
                            <img data-aos="fade-up" src="{{ logo }}" alt="RCI" *ngIf="logo">
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block">
                    <img src="/assets/images/start/img-tiles.png" class="start-tiles" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- -->    



    <!-- SHAPE SECTION --> 
    <section id="shape">
        <div class="shape-circle"></div>
        <div data-aos="fade-in" class="d-flex pt-5 justify-content-center justify-content-md-between">
            <div class="shape-text text-center text-md-start d-flex">
                <div>
                    <img src="/assets/images/shape/icon-bag.png" class="mt-4" alt="">
                </div>
                <div>
                    <h3 [innerHTML]="'shape.rci' | translate"></h3>
                    <p [innerHTML]="'shape.new-shape' | translate"></p>
                </div>
            </div>
        </div>
        <div class="shape-image justify-content-center" [style.background-image]="'url(' + ('shape.image' | translate) + ')' | safe: 'resourceURL'" (click)="show('shape.video')">
        </div>
    </section>
    <!-- -->    



    <!-- WELCOME SECTION --> 
    <section id="welcome">
        <div class="d-block d-md-flex align-items-center justify-content-around">
            <div class="col-l w-100 w-md-50 d-flex flex-column justify-content-center text-center">
                <div class="position-relative">
                    <img data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" [src]="'welcome.image' | translate | safe: 'resourceURL'" alt="" class="welcome-video-image">
                    <div class="welcome-play">
                        <button type="button">
                            <img src="/assets/images/welcome/img-play.png" alt="" (click)="show('welcome.video')" class="welcome-video-image">
                        </button>
                    </div>
                </div>
                <div>
                    <img data-aos="fade-up" src="/assets/images/welcome/img-circle.png" alt="" class="welcome-circle">
                </div>
            </div>
            <div class="welcome-text">
                <div data-aos="fade-up" class="welcome-box">
                    <h3 data-aos="fade-in" [innerHTML]="'welcome.header' | translate" class="text-uppercase text-center text-md-start"></h3>
                    <div class="d-flex justify-content-center justify-content-md-between welcome-box-item welcome-item welcome-item-{{ index }}" *ngFor="let l of 'welcome.links' | translate; let index = i;">
                        <div class="mb-4">
                            <a pageScroll href="/{{ code }}/{{ lang }}{{ l.link }}" class="welcome-item-link" [attr.data-target]="l.link">
                                <span>{{ l.text }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- BENEFITS 1 SECTION --> 
    <section id="benefits-1">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50"></div>
            <div class="benefits-text">
                <div class="benefits-box">
                    <h3 data-aos="fade-in" [innerHTML]="'benefits.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="benefits-icon align-items-center d-block d-md-flex text-center text-md-start benefits-item-{{ l.key }}" *ngFor="let l of 'benefits.list-1' | translate">
                        <div>
                            <img src="/assets/images/benefits/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="benefits-content">
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- BENEFITS 2 SECTION --> 
    <section id="benefits-2">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50"></div>
            <div class="benefits-text">
                <div class="benefits-box">
                    <h3 data-aos="fade-in" [innerHTML]="'benefits.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="benefits-icon align-items-center d-block d-md-flex text-center text-md-start benefits-item-{{ l.key }}" *ngFor="let l of 'benefits.list-2' | translate">
                        <div>
                            <img src="/assets/images/benefits/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="benefits-content">
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->


  
    <!-- MEMBERSHIP SECTION -->
    <section id="membership">
        <div class="membership-area d-flex align-items-center">
            <div class="membership-area-text">
                <h3 data-aos="fade-in" [innerHTML]="'membership.header' | translate" class="mb-5"></h3>
                <div data-aos="fade-up" class="membership-icon align-items-center d-block d-md-flex text-center text-md-start mb-4" *ngFor="let l of 'membership.list' | translate">
                    <div class="membership-image">
                        <img *ngIf="l.key" src="/assets/images/membership/img-icon-{{ l.key }}.png" alt="{{ l.description }}" class="img-fluid">
                    </div>
                    <div class="membership-content">
                        <p [innerHTML]="l.description" class="mb-0"></p>
                    </div>
                </div>
                <p [innerHTML]="'membership.legals' | translate" class="mb-0 legals" data-aos="fade-up"></p>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- SKY SECTION -->
    <section id="sky">
        <div class="sky-image d-flex justify-content-center">
            <h3 data-aos="fade-in" [innerHTML]="'sky.header' | translate"></h3>
        </div>
    </section>
    <!-- -->



    <!-- BOOK SECTION -->
    <section id="book">
        <div class="d-block d-md-flex align-items-center justify-content-around">
            <div class="col-l d-flex justify-content-center flex-column text-center w-100 w-md-50">
                <div class="video-welcome">
                    <img data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" src="/assets/images/book/img-web-{{ lang }}.jpg" alt="Image">
                </div>
                <div class="circle-welcome">
                    <a rel="noopener" target="_blank" href="{{ 'book.link' | translate }}" [attr.data-title]="'rci.com'" class="book-link">
                        <img src="/assets/images/book/img-rci-circle.png" alt="circle">
                    </a>
                </div>
            </div>
            <div class="book-text">
                <div class="book-box pb-5"> 
                    <h3 data-aos="fade-in" [innerHTML]="'book.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="book-icon align-items-center d-flex text-center text-md-start book-text-item-{{ l.key }}" *ngFor="let l of 'book.list' | translate">
                        <div>
                            <img src="/assets/images/book/img-icon-{{ l.key }}.png" alt="{{ l.description }}">
                        </div>
                        <div class="book-content w-100 text-start">
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- PURPLE SECTION -->
    <section id="purple">
        <div class="purple-image d-flex justify-content-center" [style.background-image]="'url(/assets/images/purple/img-computer-' + lang + '.png)' | safe: 'resourceURL'"></div>
    </section>
    <!-- -->
    


    <!-- SNOW SECTION --> 
    <section id="snow" class="snow">
        <div class="snow-image d-flex justify-content-star justify-content-md-center align-items-end">
            <div class="text-center mb-5">
                <h3 data-aos="zoom-in" class="aos-init aos-animate" [innerHTML]="'snow.header' | translate"></h3>
                <small class="d-block" [innerHTML]="'snow.small' | translate"></small>
            </div>
        </div>
    </section>
    <!-- -->


 
    <!-- CONTACT SECTION --> 
    <section id="contact">
        <div class="circle d-none d-md-flex justify-content-center align-items-center">
            <img data-aos="fade-up" src="/assets/images/contact/img-circle-red.png" alt="circle">
        </div>
        <h3 data-aos="fade-in" class="text-center" >
            <a href="{{ 'contact.link' | translate}}" [innerHTML]="'contact.header' | translate" target="_blank"></a>
        </h3>
        <div class="text-center d-flex flex-column justify-content-center align-items-center">
            <div class="mt-3 mt-md-0">
                <div class="text-start contact-social-item mb-4 aos-init aos-animate" data-aos="fade-up" *ngFor="let l of 'contact.social' | translate"> 
                    <a rel="noopener" href="{{ l.link }}" target="_blank" data-aos="fade-up" class="d-block text-center text-md-start contact-social-link" [attr.data-social]="l.key">
                        <img src="/assets/images/contact/img-{{ l.key }}.png" alt="{{ l.key }}" class="me-3" width="60"> <span class="d-block d-md-inline">{{ l.title ? l.title : l.link }}</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    



    <!-- VIDEO MODAL -->
    <div bsModal #mVideo="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name" (onHide)="hide($event)">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <img src="/assets/images/common/img-logo.png" alt="">
                    </h5>
                    <button type="button" data-dismiss="modal" aria-label="Close" (click)="mVideo.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe width="100%" height="315" [src]="video | safe: 'resourceURL'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -->

</div>












