import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router'; 
import { TranslateService } from '@ngx-translate/core';
import AOS from 'aos';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-brochure',
    templateUrl: './brochure.component.html',
    styleUrls: ['./brochure.component.scss']
})

export class BrochureComponent implements OnInit {

    @ViewChild('mVideo', { static: false }) public mVideo: ModalDirective;
    langs:any   = [];
    video:string;
    home:boolean = true;
    loaded:boolean = false;
    lang:string = '';
    logo:string = '';
    imgs:string = '';
    code:string = '';

    constructor(public translate: TranslateService, protected activated: ActivatedRoute, private service:ApiService, private location: Location) {

        this.langs = [];
        for (var p in environment.langs) {
            this.langs.push({lang:p, label:environment.langs[p]});
        }

        this.activated.paramMap.subscribe(paramMap => {
            
            let lang = paramMap.get('lang');
            let code = paramMap.get('code');
            
            if (environment.langs[lang]) {                
                this.translate.use(lang);
                this.lang = lang;
            }

            this.translate.get('start.logo').subscribe((translated: string) => {
                this.logo = translated;
                this.validate(code);
            });    

            this.translate.get('start.image').subscribe((translated: string) => {
                this.imgs = translated;
            });

        });

    }

    ngOnInit(): void {
        
    }

    change(event) {
        this.translate.use(this.lang);
        if (environment.default == this.code) {
            this.translate.get('start.logo').subscribe((translated: string) => {
                this.logo = translated;
            });
            this.translate.get('start.image').subscribe((translated: string) => {
                this.imgs = translated;
            });
            this.location.go('/' + this.code + '/' + this.lang);
        }
    }

    validate(code) {
        if (environment.default != code) {
            this.load(code);
        } else {
            setTimeout(()=>{
                this.code = code;
                this.start();
            }, 250);
        } 
    }

    load(code) {
        this.service.call('company/' + environment.brochure + '/' + code, Object, 'get').subscribe(response => {    
            if (response.status == 200) {
                this.logo = response.data.logo;
                this.imgs = response.data.image;
                this.code = code;
            }
            this.start();
        });
    }

    start() {
        this.loaded = true;
        AOS.init();
        this.scroll();
    }

    show(video) {
        if (video.indexOf('http') === -1) {
            this.translate.get(video).subscribe((translated: string) => {
                this.video = translated;
                this.display();
            });
        } else {
            this.video = video;
            this.display();
        }        
    }

    display() {
        setTimeout(()=>{
            this.mVideo.show();
        }, 200);
    }

    hide(e) {
        this.video = null;
    }

    @HostListener('window:scroll', [])
    scroll() {
        var anchor = document.getElementById('where');
        if (anchor) {
            this.home = anchor.offsetTop - window.innerHeight < window.pageYOffset;
        }
    }

    @HostListener('window:resize', [])
    resize() {
        this.scroll();
    }

}
